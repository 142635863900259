<template>
  <module
      ref="module"
      id="matsTable"
      titleIcon="fa fa-arrows-h"
      :title="txt.accountsLocationTxt"
      :use-default-list="false"
    >
    <template slot="toolbar-global">
      <migrate-m-a-t></migrate-m-a-t>
    </template>
    <div slot="global">
      <div v-if="inProgress" class="text-center">
        <h2>{{ $t('loadingtxt') }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>
      <div v-else class="row">
    <div class="col-md-12">
      <div>
        <div class="row">
          <div class="col-sm-2">
            <label> {{ sortbyTxt }} </label>
            <el-select v-model="sortby.column" :placeholder="sortbyTxt"
                       v-on:change="sortByCol">
              <el-option class="select-default"
                         v-for="item in sortby.columns"
                         :key="item.prop" :label="item.label" :value="item.prop">
              </el-option>
            </el-select>
          </div>


          <div class="col-sm-2">
            <label> {{ sortbyOrder }} </label>
            <el-select
              v-model="sortby.order" :placeholder="sortbyOrder"
              v-on:change="sortByCol">
              <el-option class="select-default"
                         v-for="item in sortby.orders"
                         :key="item.prop" :label="item.label" :value="item.prop">
              </el-option>
            </el-select>
          </div>

          <div class="col-sm-2">
            <label> {{ perpageTxt }} </label>
            <el-select
              v-model="pagination.perPage" :placeholder="perpageTxt">
              <el-option class="select-default"
                         v-for="item in pagination.perPageOptions"
                         :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>

          <div class="col-sm-6">
            <div class="pull-right">
              <label> {{ searchtxt }}</label>
              <fg-input class="input-sm"
                        v-model="searchQuery"
                        addon-right-icon="nc-icon nc-zoom-split">
              </fg-input>
            </div>
          </div>
        </div>

        <el-table class="table-striped"
                  :data="queriedData"
                  border>
          <div slot="empty">{{ $t('norecordsfounded') }}</div>
          <el-table-column :label="txt.account" align="left" width="200">
            <template slot-scope="props">
                <span v-on:click="copyit(props.row.account)" class="clickable">
                  <el-tooltip :content="props.row.broker">
                   <div class="logo-broker">
                     <img v-if="props.row.logo && props.row.logo != 'null'"
                          :src="`https://hoko.ams3.digitaloceanspaces.com/brokerlogos/${props.row.logo}.png`">
                     <img v-else alt="Broker">
                   </div>
                  </el-tooltip>
                  <span style="margin-top: 5px">
                    {{ props.row.account }}
                  </span>
                </span>
                <span>
                  <el-tooltip content="Follower">
                    <i class="fa fa-users fa-lg" v-if="props.row.type == 'FOLLOWER'" aria-hidden="true" style="color: #51cbce;"></i>
                  </el-tooltip>
                  <el-tooltip content="Master">
                    <i class="fa fa-star fa-lg" v-if="props.row.type == 'MASTER'" aria-hidden="true" style="color: gold;"></i>
                  </el-tooltip>
                </span>
            </template>
          </el-table-column>

          <el-table-column :label="txt.userid" align="center" width="270">
            <template slot-scope="props">
              <span v-on:click="copyit(props.row.userid)" class="clickable">
                {{ props.row.userid }}
              </span>
            </template>
          </el-table-column>

          <el-table-column :label="txt.server" align="center">
            <template slot-scope="props">
              <span v-on:click="copyit(props.row.server)" class="clickable">
                {{ props.row.server }}
              </span>
            </template>
          </el-table-column>

          <el-table-column :label="txt.strategy" align="center">
            <template slot-scope="props">
              <span v-on:click="copyit(props.row.strategy)" class="clickable">
                {{ props.row.strategy }}
              </span>
            </template>
          </el-table-column>


          <el-table-column :label="txt.matServer" align="center">
            <template slot-scope="props">
                <span v-on:click="copyit(props.row.mat_server)" class="clickable">
                  {{ props.row.mat_server }}
                </span>
            </template>
          </el-table-column>

          <el-table-column :label="txt.action" align="center" width="150px">
              <template slot-scope="props">
                <el-tooltip content="Copy Key" :open-delay="100" placement="top" >
                  <p-button v-on:click="copyit(props.row.password)" link>
                      <i class="fa fa-key"></i>
                  </p-button>
                </el-tooltip>
              </template>
          </el-table-column>

        </el-table>

        <input id="clipit" type="text" v-model="clipboard" style="display: none">

        <div class="col-sm-6 pagination-info">
          <p class="category">Showing {{ from + 1 }} to {{ to }} of {{ total }} entries</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="pagination.total">
          </p-pagination>
        </div>
      </div>
  </div>




  </div>
    </div>
  </module>
</template>

<script>

import Vue from 'vue';
import {Tooltip,Table, TableColumn, Pagination} from 'element-ui';
import PPagination from 'src/components/UIComponents/Pagination.vue'
import swal from "sweetalert2";
import MigrateMAT from "@/components/Dashboard/Views/Dashboard/Strategies/MigrateMAT";

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination);

export default {
  name: "Migration",
  components: {
    [Tooltip.name]: Tooltip,
    PPagination,
    MigrateMAT,
  },
  computed: {
    pagedData () {
      return this.tableData.slice(this.from, this.to)
    },
    queriedData() {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length
        return this.pagedData
      }
      let result = this.tableData
        .filter((row) => {
          let isIncluded = false
          for (let key of this.propsToSearch) {
            let searchRegex = new RegExp(this.searchQuery, 'gi');
            if (row[key] && row[key].toString().match(searchRegex) !== null) {
              isIncluded = true
            }
          }
          return isIncluded
        })
      this.pagination.total = result.length
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    },
  },
  data() {
    return {
      perpageTxt: this.$t('perpage'),
      sortbyTxt: this.$t('sortby'),
      sortbyOrder: this.$t('sortbyorder'),
      searchtxt: this.$t('searchtxt'),
      txt: {
        accountsLocationTxt: this.$t('accountsLocation'),
        regenerateSetFilesFollowerTxt: this.$t('regenerateSetFilesFollower'),
        regenerateSetFilesMasterTxt: this.$t('regenerateSetFilesMaster'),
        account: this.$t('account'),
        userid: this.$t('userid'),
        server: this.$t('servertxt'),
        strategy: this.$t('strategytxt'),
        matServer: this.$t('matServer'),
        action: this.$t('action'),
      },
      searchQuery: '',
      propsToSearch: ['strategy', 'userid','account', 'mat_server'],
      colSort: '',
      sortby: {
          column: 'userid',
          order: 'asc',
          orders: [
            {
              prop: 'asc',
              label: this.$t('ascending')
            },
            {
              prop: 'desc',
              label: this.$t('descending')
            }
          ],
          columns: [
            {
              prop: 'account',
              label: this.$t('account')
            },
            {
              prop: 'strategy',
              label: this.$t('strategytxt')
            },
            {
              prop: 'mat_server',
              label: this.$t('mattxt')
            },
            {
              prop: 'userid',
              label: this.$t('userid')
            },
            {
              prop: 'server',
              label: this.$t('servertxt')
            },
            {
              prop: 'type',
              label: this.$t('type')
            },
          ]
        },
      inProgress: true,
      tableData: [],
      clipboard: '',
      pagination: {
          perPage: 20,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
      },

    }
  },
  methods: {
    loadAccountsLocation(resp) {
      if(!resp.success) {
        this.$toast.warning(this.$t('datatablerefresherror'));
        return;
      }
      this.tableData = resp.data;
      this.sortByCol();
      this.inProgress = false;
    },
    failOp(resp) {
      console.log(resp)
    },
    copyit(value) {
      let cb = document.getElementById("clipit");
      cb.style.display = 'block';
      cb.value = value;
      cb.select();
      document.execCommand('copy');
      cb.style.display = 'none';
      this.$toast.success(`${value} Copied!`)
    },
    sortItNum(a, b) {
      if (this.sortby.order == 'asc') {
        return a[this.colSort] - b[this.colSort];
      }
      return b[this.colSort] - a[this.colSort];
    },
    sortIt(a, b) {
      if (this.sortby.order == 'asc') {
        if (a[this.colSort] < b[this.colSort])
          return -1;
        if (a[this.colSort] > b[this.colSort])
          return 1;
      }
      if (b[this.colSort] < a[this.colSort])
        return -1;
      if (b[this.colSort] > a[this.colSort])
        return 1;
      return 0;
    },
    sortByCol() {
      this.colSort = this.sortby.column;
      if (this.sortby.column !== 'account') {
        this.tableData.sort(this.sortIt);
      } else {
        this.tableData.sort(this.sortItNum);
      }
    },
    callAccountsLocation(){
      this.$listAccountsLocation().then(this.loadAccountsLocation, this.failOp)
    }
  },
  mounted() {
    this.callAccountsLocation()
  }
}
</script>

<style scoped lang="scss">
.table-x {
  td, th {
    padding-left: 15px;
    padding-right: 15px;
    font-weight: bold;
  }
}
.logo-broker {
  margin: 0;
  display: inline-block;
}
.logo-broker img {
  border: solid 1px silver;
  border-radius: 100%;
  max-width: 35px;
  max-height: 35px;
}

</style>
